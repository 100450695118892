import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 加载全局样式
import './styles/index.less'

// 自动设置REM基准值（html 标签字体大小)
import 'amfe-flexible'

import Vant from 'vant'
import 'vant/lib/index.css'

// 创建Vue根实例，将 router、 store配置到根实例中
// 把 App 根组件渲染到 #app 节点

createApp(App).use(store).use(router).use(Vant).mount('#app')
