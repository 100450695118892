import { createRouter, createWebHashHistory } from 'vue-router'

// 路由表
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/IndexView.vue')
  },
  {
    path: '/',
    component: () => import('@/views/layout/IndexView.vue'),
    children: [
      {
        path: '', // 默认子路由
        name: 'home',
        component: () => import('@/views/home/IndexView.vue')
      },
      {
        path: '/video',
        name: 'video',
        component: () => import('@/views/video/IndexView.vue')
      },
      {
        path: '/tianjia',
        name: 'tianjia',
        component: () => import('@/views/tianjia/IndexView.vue')
      },
      {
        path: '/zhibo',
        name: 'zhibo',
        component: () => import('@/views/zhibo/IndexView.vue')
      },
      {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my/IndexView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
